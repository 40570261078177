<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="JobOpenings"></company-submenu>

    <div class="submenu-content">
      <div style="text-align: center">
        <img :src="jobOpening_banner[0]" style="width:100%; max-width:1200px" />
      </div>
      <div v-html="jobOpening_para"></div>
      <nav
        class="navbar navbar-expand-lg navbar-light"
        style="background-color:#e6e4df;"
      >
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <form
              @submit.prevent="handleSearch"
              class="d-flex"
              style="margin-left:5px"
            >
              <input
                v-model="search_keyword"
                class="form-control me-2"
                type="search"
                placeholder="Search by Title"
                aria-label="Search"
              />
              <button class="btn btn-outline-success" type="submit">
                Search
              </button>
            </form>
            <div class="float-end">
              <div class="row">
                <div class="col-sm-8" style="margin-left:5px;">
                  <select
                    v-model="location"
                    aria-placeholder="Location"
                    style="background-color:#e6e4df;"
                    class="form-select"
                    @change="onChangeLocation($event)"
                  >
                    <option value="">Location</option>
                    <option value="San Jose">San Jose, CA</option>
                    <option value="Glendale Heights"
                      >Glendale Heights, IL</option
                    >
                    <option value="City of Industry"
                      >City of Industry, CA</option
                    >
                    <option value="Edison">Edison, NJ</option>
                    <option value="Hanover Park">Hanover Park, IL</option>
                    <option value="Miami">Miami, FL</option>
                    <option value="Norcross">Norcross, GA</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="tb">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Position</th>
              <th scope="col">Type</th>
              <th scope="col">Location</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(job, key) in job_list" :key="key">
              <tr>
                <th>
                  <a :href="job.link" target="blank">{{ job.position }}</a>
                </th>
                <td>{{ job.type }}</td>
                <td>{{ job.location }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- end of smart_recruiters -->
      <!-- <div id="append_here"></div> -->
      <br>
      <div>
        <div style="padding-bottom:10px"><strong>EEOC Statement</strong></div>
        <p>
          Ma Labs welcomes and encourages diversity and inclusion in the
          workplace. Ma Labs is committed to equal employment opportunity
          regardless of race, color, religion, sex (including pregnancy,
          childbirth, or related medical conditions), gender identity, gender
          expression, national origin, ancestry, citizenship, age, physical or
          mental disability, military or veteran status, marital status,
          domestic partner status, sexual orientation, genetic information, or
          any other basis protected by applicable law. Ma Labs is also committed
          to providing reasonable accommodations for candidates with
          disabilities in our recruiting process. If you need any accommodations
          due to a disability, please let us know.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "JobOpenings",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const jobOpening_banner = ref([]);
    const jobOpening_para = ref("");
    const error = ref(null);
    let jobOpening_url = "content/pages/50";
    const job_list = ref([]);
    const location = ref("");
    const search_keyword = ref("");
    const route = useRoute();
    const router = useRouter();

    //breadcrumb
    const breadcrumb_links = ref([]);
    breadcrumb_links.value.push({ text: "Company", link: "" });
    breadcrumb_links.value.push({ text: "Careers", link: "Careers" });
    breadcrumb_links.value.push({ text: "Job Openings", link: "JobOpenings" });

    var SortByLocation = "";
    var SortByLocation_param = "";
    if (typeof route.query.city != "undefined" && route.query.city != "") {
      SortByLocation = route.query.city;
      SortByLocation_param = "city=" + SortByLocation;
      location.value = route.query.city;
    }
    var SearchByKey = "";
    var SearchByKey_param = "";
    if (typeof route.query.q != "undefined" && route.query.q != "") {
      SearchByKey = route.query.q;
      SearchByKey_param = "q=" + SearchByKey;
      search_keyword.value = route.query.q;
    }

    var smart_recruiters_url =
      "search/job-openings/" +
      "?" +
      SortByLocation_param +
      "&" +
      SearchByKey_param;
    console.log("this is a test", smart_recruiters_url);

    // get static contact
    getAPI
      .get(jobOpening_url)
      .then((response) => {
        if (response.status === 200) {
          let blocks = response.data.body;
          for (let index in blocks) {
            if (blocks[index].type == "image") {
              jobOpening_banner.value.push(blocks[index].value.large.src);
            }
            if (blocks[index].type == "paragraph") {
              jobOpening_para.value = blocks[index].value;
            }
          }
        }
      })
      .catch((err) => {
        error.value = "No page Found";
      });

    // connect to smartrecruter

    getAPI
      .get(smart_recruiters_url)
      .then((response) => {
        let jobs = response.data.content;
        for (let job in jobs) {
          job_list.value.push({
            position: jobs[job].name,
            type: jobs[job].typeOfEmployment.label,
            location: jobs[job].location.city + "," + jobs[job].location.region,
            date: jobs[job].releasedDate.substring(0, 10),
            link: jobs[job].public_url,
          });
        }
      })
      .catch((err) => {
        let error2 = "No page Found";
      });

    // search by location
    function onChangeLocation(event) {
      location.value = event.target.value;
      var url =
        "/company/job-openings/" +
        "?" +
        "city=" +
        location.value +
        "&" +
        "q=" +
        search_keyword.value;
      router.push(url);
    }

    // search by keyword
    const handleSearch = () => {
      var url =
        "/company/job-openings/" +
        "?" +
        "q=" +
        search_keyword.value +
        "&" +
        "city=" +
        location.value;
      router.push(url);
    };

    return {
      jobOpening_banner,
      jobOpening_para,
      error,
      job_list,
      onChangeLocation,
      handleSearch,
      search_keyword,
      location,
      breadcrumb_links,
    };
  },
};
</script>

<style scoped>
a {
  color: #6aa2cc;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #2b7ebd;
  background-color: transparent;
  text-decoration: underline;
}
.tb {
  height: 500px;
  overflow: auto;
}
</style>
